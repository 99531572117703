@import url('./assets/font-awesome/css/all.min.css');
/* @import url('https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css'); */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/iconfont/MaterialIcons-Regular.ttf); /* For IE6-8 */
}

.material-icons {
 font-family: 'Material Icons';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;  /* Preferred icon size */
 display: inline-block;
 line-height: 1;
 text-transform: none;
 letter-spacing: normal;
 word-wrap: normal;
 white-space: nowrap;
 direction: ltr;

 /* Support for all WebKit browsers. */
 -webkit-font-smoothing: antialiased;
 /* Support for Safari and Chrome. */
 text-rendering: optimizeLegibility;

 /* Support for Firefox. */
 -moz-osx-font-smoothing: grayscale;

 /* Support for IE. */
 font-feature-settings: 'liga';
}

.vertical-align-sub {
  vertical-align: sub!important;
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Open sans', sans-serif!important; }
